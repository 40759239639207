import React from 'react';
import { IonRouterLink } from "@ionic/react";
import { PageLinks } from "../../common/Constants";
import { APIData } from '../../common/DataTypes';
import * as Utilities from '../../common/Utilities';
import { useAppStateAPI } from '../../common/AppStateAPI';
import { useHistory, useLocation } from 'react-router';

const ProductListItem: React.FC<{ product: APIData.Product, addToWishlist: (productID: number) => void, removeFromWishlist: (wishlistItemID: number) => void }> = ({ product, addToWishlist, removeFromWishlist }) => {

    const location = useLocation();
    const history = useHistory();
    const { customerLogin, setCustomerLoginRedirectLink } = useAppStateAPI();

    return (
        <div className="single-popular-product">
            <IonRouterLink routerLink={PageLinks.PRODUCT_DETAILS + '/' + product.product_url_name}>
                <div className="sp-thumb">
                    <img src={product.product_default_image} alt={product.product_default_image_caption} />
                    {/* <div className="pro-badge">
                        <p className="sale"> Sale </p>
                    </div> */}
                    <a href='#' onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (customerLogin) {
                            if (product.wishlist_item_id) {
                                removeFromWishlist(product.wishlist_item_id);
                            } else {
                                addToWishlist(product.product_id);
                            }
                        } else {
                            setCustomerLoginRedirectLink(location.pathname);
                            history.push(PageLinks.LOGIN);
                        }
                    }} className={`sp-wishlist${product.wishlist_item_id ? ' active' : ''}`}> <i className="twi-heart2"> </i></a>
                </div>
            </IonRouterLink>
            <div className="sp-details">
                <h4>{product.product_name}</h4>
                <div className="product-price clearfix">
                    <span className="price">
                        {/* {product.product_start_price !== product.product_start_selling_price && (
                            <del><span><span className="woocommerce-Price-currencySymbol">₹</span>{Utilities.formatIndianNumber(parseFloat(product.product_start_price))}</span></del>
                        )} */}
                        <ins><span><span className="woocommerce-Price-currencySymbol">₹</span> {Utilities.formatIndianNumber(parseFloat(product.product_start_selling_price))}</span></ins>
                    </span>
                    <IonRouterLink className="sp-cart" routerLink={PageLinks.PRODUCT_DETAILS + '/' + product.product_url_name}> <i className="twi-cart-plus"> </i><span>Add to cart</span> </IonRouterLink>
                </div>
                {/* <div className="sp-details-hover">
                </div> */}
            </div>
        </div>
    )
}

export default ProductListItem;