import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';

import { Helmet } from 'react-helmet';

const HowToInstall: React.FC = () => {

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Help Center</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/help-center' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Help Center' />
                <meta property='og:description' content='VENETZ | Help Center' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">Check Me Before Ordering</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Help Center</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="history-section help_center">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="sec-title">How to Install your Ready to use Mosquito nets with frames</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>
                                            <div className='helpcenter_tab'>
                                                <iframe src="https://www.youtube.com/embed/exzGW5_8Z38?si=UUplWy5stJENOEhU" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default HowToInstall;
