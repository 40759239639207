import React from 'react';
const HomeHowToMeasure: React.FC = () => {
    return (
        <div>
            <section className="coupone-discount-sec-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-5">
                            <div className="dis-product-detail">
                                <h4>How To Measure?</h4>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/XqcM8nwCm7o?controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="dis-product-detail">
                                <h4>How To Measure?</h4>
                                <p>
                                    Features of Venetz Mosquito net with ready to install frame <br />
                                    Highly Durable Mosquito net which lasts upto 10 years  <br />
                                    Flame Resistant  <br />
                                    Easy Machine Washable protective nets  <br />
                                    Recyclable Mosquito net material  <br />
                                    Smooth Finish  <br />
                                    6 color options  <br />
                                    <br />
                                    <strong>Technical Details of Ready to install bed nets:</strong><br />
                                    Made from Polyethylene <br />
                                    One net will last upto 10 years of span <br />
                                    Non tearable <br />
                                    <br />
                                    <strong>Why to use Venetz Bed Nets? </strong><br />
                                    For a Peaceful Sleep <br />
                                    Immune Booster on Disturb less sleep <br />
                                    Warmth Feeling inside <br />
                                    Ideal Temperature maintained inside the Protective bed net <br />
                                    Investing 60 paise per day for total Familys Health <br />
                                    Undisturbed Sleep improves Kids rational thinking <br />
                                    Buying a net is an priceless investment for your Elderly and KidS Health <br />
                                    <br />
                                    BEST MOSQUITO NET FOR BED WITH STAND TO PREVENT <br />
                                    FROM MOSQUITO BITES
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-service">
                                <img src="assets/images/home/truck2.png" alt="" />
                                <h4>Free Shipping</h4>
                                <p>Free Shipping of Mosquito nets with ready to use frame on orders above Rs.500</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-service">
                                <img src="assets/images/home/undo2.png" alt="" />
                                <h4>Hassle Free Installations</h4>
                                <p>The protective mosquito net Comes with both Mosquito Net and the ready to install Mosquito net Frame, which can be installed to any bed within 5 minutes of time.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-service">
                                <img src="assets/images/home/headphone2.png" alt="" />
                                <h4>Customize Product</h4>
                                <p>We are ready to cater your custom sized protective nets for Bed rooms, Outdoor gardens and for Pets. Click here if you are looking for Custom sized mosquito nets</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default HomeHowToMeasure;