import { IonRouterLink } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { PageLinks } from "../../common/Constants";
import { APIData } from "../../common/DataTypes";
import * as Utilities from '../../common/Utilities';

const OrderDetailCard: React.FC<{ orderDetail: APIData.OrderDetail }> = ({ orderDetail }) => {

    const [itemsCount, setItemsCount] = useState(0);
    const [displayAddress, setDisplayAddress] = useState<'ship' | 'bill'>('ship');

    useEffect(() => {
        let cnt = 0;
        orderDetail.order_items.forEach(item => cnt += item.order_item_quantity);
        setItemsCount(cnt);
    }, [orderDetail]);

    return (
        <div className="ord_container p_left">
            <div className="ord_no_container p_left">
                <div className="ord_no p_left">
                    <ul>
                        <li className="ord_no_li"> <span className="ord_txt">Order Number : </span> <span className="orderno">{orderDetail.order_reference_number}</span> </li>
                        <li className="ord_no_li"> <span className="or_pl">Order Placed : </span> <span className="ord_inner dt">{Utilities.getFormattedLocalDateTime(orderDetail.order_add_time)}</span> <span className="ord_inner item"><span className="itm_no">{itemsCount}</span> {itemsCount > 1 ? 'Items' : 'Item'}</span>
                        </li>
                    </ul>
                </div>
                <div className="ord_total">
                    <ul>
                        <li className="t_ord p_right"> </li>
                        <li className="t_ord"> <span className="ord_txt p_left">Order Total: </span><span className="total p_left"><i className="fa fa-inr"></i> {Utilities.formatIndianNumber(parseFloat(orderDetail.order_item_total_amount))}</span> </li><br />
                        <li className="t_ord"> <span className="ord_txt p_left">Order Status : </span><span className="total p_left">{Utilities.getOrderStatusString(orderDetail.order_status)}</span> </li>
                    </ul>
                </div>
            </div>
            <div className="ord_detail_ctn pmt_head p_left">Product Details</div>
            {orderDetail.order_items.map((orderItem, index) => {
                return (
                    <div className="dtl_ctn p_left wdt_or brd_btm in_tran" key={index}>
                        <div className="ctn_img p_left">
                            <div className="pr_img">
                                {orderItem.order_item_customized ?
                                    <img src={orderItem.product_image} alt={orderItem.product_image_caption} />
                                    :
                                    <IonRouterLink routerLink={PageLinks.PRODUCT_DETAILS + '/' + orderItem.product_url_name}>
                                        <img src={orderItem.product_image} alt={orderItem.product_image_caption} />
                                    </IonRouterLink>
                                }
                            </div>
                        </div>
                        <div className="im_dt p_left">
                            <div className="im_name">
                                {orderItem.order_item_customized ?
                                    <>
                                        <a href="#" onClick={e => e.preventDefault()}>{orderItem.order_item_custom_title}</a><br />
                                        {orderItem.order_item_custom_description}
                                    </>
                                    :
                                    <>
                                        <IonRouterLink className="text-bold im_name_text" href={PageLinks.PRODUCT_DETAILS + '/' + orderItem.product_url_name}>{orderItem.product_name}</IonRouterLink><br />
                                        {orderItem.product_short_description}
                                    </>
                                }
                            </div>
                            {parseFloat(orderItem.order_item_final_price) > 0 && <div className="im_prc"><i className="fa fa-rupee"></i> {Utilities.formatIndianNumber(parseFloat(orderItem.order_item_final_price))}</div>}
                            <div className="im_dtl"><span><span>Qty: </span><span className="qt">{orderItem.order_item_quantity}</span></span>
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className="pay_dtl p_left" id="payment_info">
                <div className="addr_dtl p_left">
                    <div className={`ship_addr p_left ${displayAddress === 'ship' ? 'bg_active' : ''}`} onClick={() => setDisplayAddress('ship')}>Shipping Address</div>
                    <div className={`bill_addr p_left ${displayAddress === 'bill' ? 'bg_active' : ''}`} onClick={() => setDisplayAddress('bill')}>Billing Address</div>
                    {displayAddress === 'ship' && (
                        <div className="ship_addr_dtl p_left">
                            <span className="name">{orderDetail.order_group_ship_address_full_name}</span>
                            <span className="str1">{orderDetail.order_group_ship_address_line1}, {orderDetail.order_group_ship_address_line2}</span>
                            {orderDetail.order_group_ship_address_landmark !== '' && (
                                <span className="str1">{orderDetail.order_group_ship_address_landmark}</span>
                            )}
                            <span className="str1">{orderDetail.order_group_ship_address_city}, {orderDetail.order_group_ship_address_state_name}</span>
                            <span className="str1">{orderDetail.order_group_ship_address_country_name} - {orderDetail.order_group_ship_address_postal_code}</span>
                            <span className="str1">Mobile: {orderDetail.order_group_ship_address_phone}</span>
                        </div>
                    )}
                    {displayAddress === 'bill' && (
                        <div className="bill_addr_dtl p_left">
                            <span className="name">{orderDetail.order_group_bill_address_full_name}</span>
                            <span className="str1">{orderDetail.order_group_bill_address_line1}, {orderDetail.order_group_bill_address_line2}</span>
                            {orderDetail.order_group_bill_address_landmark !== '' && (
                                <span className="str1">{orderDetail.order_group_bill_address_landmark}</span>
                            )}
                            <span className="str1">{orderDetail.order_group_bill_address_city}, {orderDetail.order_group_bill_address_state_name}</span>
                            <span className="str1">{orderDetail.order_group_bill_address_country_name} - {orderDetail.order_group_bill_address_postal_code}</span>
                            <span className="str1">Mobile: {orderDetail.order_group_bill_address_phone}</span>
                        </div>
                    )}
                </div>
                <div className="ship_tl p_left">
                    <div className="ship_item"> <span className="item_innr_txt">Item(s) Subtotal</span> <span className="p_lft">:</span><span className="item_innr_val"><i className="fa fa-rupee"></i> {Utilities.formatIndianNumber(parseFloat(orderDetail.order_item_total_amount))}</span> </div>
                    <div className="shipping"> <span className="shipping_txt">Shipping</span><span className="p_lft">:</span> <span className="shipping_val">{parseFloat(orderDetail.order_shipping_amount) > 0 ? Utilities.formatIndianCurrency(parseFloat(orderDetail.order_shipping_amount)) : 'FREE'}</span> </div>
                    {parseFloat(orderDetail.order_cod_charge) > 0 && (
                        <div className="total"> <span className="total_txt">COD Charges</span><span className="p_lft">:</span> <span className="total_val"><i className="fa fa-rupee"></i> {Utilities.formatIndianNumber(parseFloat(orderDetail.order_cod_charge))}</span> </div>
                    )}
                    <div className="total"> <span className="total_txt">Net Payment</span><span className="p_lft">:</span> <span className="total_val"><i className="fa fa-rupee"></i> {Utilities.formatIndianNumber(parseFloat(orderDetail.order_total_amount))}</span> </div>
                    <div className="pym_mode"> <span className="mode_txt">Payment Mode</span><span className="p_lft">:</span> <span className="mode_val"> {orderDetail.payment_method_name} </span> </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetailCard;