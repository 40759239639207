//import { IonCol, IonGrid, IonRouterLink, IonRow } from '@ionic/react';
import React from 'react';
//import { PageLinks } from '../../common/Constants';
const HomeProductCategories: React.FC = () => {
    return (
        <div>
            <section className="categorie-section">
                {/* <div className="sec-heading rotate-rl">Product <span> Categories</span></div> */}
                <div className="container-fluid">
                    {/* <IonGrid>
                        <IonRow>
                            <IonCol size="12" size-md="12" size-lg="12">
                                <h2 className="sec-title">Product Categories</h2>
                                <p className="sec-desc">
                                    Lorem Ipsum is simply dummy text
                                </p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" size-md="4" size-lg="3">
                                <div className="single-cate-bg">
                                    <IonRouterLink routerLink={PageLinks.PRODUCT_DETAILS} className="single-cate">
                                        <img src="assets/images/home/home_category_1.jpg" />
                                    </IonRouterLink>
                                    <p>Mosquito Nets For Beds</p>
                                </div>
                            </IonCol>
                            <IonCol size="12" size-md="4" size-lg="3">
                                <div className="single-cate-bg">
                                    <IonRouterLink routerLink={PageLinks.PRODUCT_DETAILS} className="single-cate">
                                        <img src="assets/images/home/home_category_2.jpg" />
                                    </IonRouterLink>
                                    <p>Outdoor Mosquito Nets</p>
                                </div>
                            </IonCol>
                            <IonCol size="12" size-md="4" size-lg="3">
                                <div className="single-cate-bg">
                                    <IonRouterLink routerLink={PageLinks.PRODUCT_DETAILS} className="single-cate">
                                        <img src="assets/images/home/home_category_3.jpg" />
                                    </IonRouterLink>
                                    <p>Mosquito Nets For Pets</p>
                                </div>
                            </IonCol>
                            <IonCol size="12" size-md="4" size-lg="3">
                                <div className="single-cate-bg">
                                    <IonRouterLink routerLink={PageLinks.PRODUCT_DETAILS} className="single-cate">
                                        <img src="assets/images/home/home_category_4.jpg" />
                                    </IonRouterLink>
                                    <p>Bags and Pouches</p>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid> */}
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="sec-title">Why Venetz Mosquito Nets?</h2>
                            <p className="sec-desc">
                                The best venetz mosquito nets Mosquitoes are something beyond irritation — they can spread destructive sicknesses like West Nile infection and Heartworm to your pets. That is the reason your first line of safeguard ought to consistently be month-to-month Heartworm deterrents and a wide range of bug and tick medicine. Together, these two preventatives are incredibly effective at protecting your puppy. Most bug and tick preventives have a fixing to kill mosquitoes on contact — before they bit. What’s more, Heartworm protecting works by killing Heartworm hatchlings before they can become grown-up worms if your pet becomes contaminated.
                            </p>
                        </div>
                        <div className="col-lg-12 mt-20">
                            <h2 className="sec-title">Durable Mosquito nets which can last upto 10 years</h2>
                            <p className="sec-desc">
                                Venetz Mosquito Net for beds with ready to install frames can be assembled easily. The Protective net is very Durable made of recyclable material which serves to be the best solution for the Protection from Mosquito bites.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="cate-shage"><img src="assets/images/home/shape1.png" alt="" /></div>
            </section>
            <section className="parallax-section">
                <div className="col-lg-12 col-md-12 p-0">
                    <img src="assets/images/home/bg_text.png" />
                </div>
            </section>
        </div>
    );
};
export default HomeProductCategories;