import React, { useEffect, useState } from 'react';
import { BackButtonEvent, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { App } from "@capacitor/app";
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';

import Home from './pages/Home';
import Category from './pages/Category';
import ProductDetails from './pages/ProductDetails';
import ProductCustomize from './pages/ProductCustomize';
import Checkout from './pages/Checkout';
import Cart from './pages/Cart';
import Login from './pages/Login';
import MyOrders from './pages/MyOrders';
import MyProfile from './pages/MyProfile';
import MyAddress from './pages/MyAddress';
import ChangePassword from './pages/ChangePassword';
import MyWishlist from './pages/MyWishlist';
import Contact from './pages/Contact';
import HelpCenter from './pages/HelpCenter';
import OrderDetails from './pages/OrderDetails';
import { Configs, PageLinks } from './common/Constants';
import { useAppStateAPI } from './common/AppStateAPI';
import * as Utilities from './common/Utilities';
import * as ServerAPI from './common/ServerAPI';
import CheckoutResponse from './pages/CheckoutResponse';
import Testimonials from './pages/Testimonials';
import Menu from './components/Menu';
import ForgotPassword from './pages/ForgotPassword';
import Aboutus from './pages/AboutUs';
import ShippingPolicy from './pages/ShippingPolicy';
import TermsAndConditions from './pages/TermsandConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CancellationPolicy from './pages/CancellationPolicy';
import HowVenetzWorks from './pages/HowVenetzWorks';
import HowToOrder from './pages/HowToOrder';
import HowToMeasure from './pages/HowToMeasure';
import HowToInstall from './pages/HowToInstall';


const AppNavigate: React.FC = () => {

    const { showToastMsg, setCustomerLogin, setMenuCategories, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [appInitialized, setAppInitialized] = useState(0);
    const [fcmID, setFCMID] = useState('');

    let exitApp = false;
    useEffect(() => {
        document.addEventListener('ionBackButton', ((ev: BackButtonEvent) => {
            ev.detail.register(10, (processNextHandler: () => void) => {
                if (window.location.pathname.startsWith(PageLinks.HOME)) {
                    if (exitApp) {
                        App.exitApp();
                    } else {
                        exitApp = true;
                        showToastMsg('info', "Tap on Back Button again to Exit");
                        setTimeout(() => exitApp = false, Configs.ToastDuration);
                    }
                } else {
                    processNextHandler();
                }
            });
        }) as EventListener);

        if (Capacitor.getPlatform().toLowerCase() !== 'web') {
            registerFCM();
        } else {
            Utilities.getPersistedFCMID().then(responseFcmID => {
                if (responseFcmID) {
                    setFCMID(responseFcmID);
                } else {
                    const genFCMID = Utilities.generateRandomString(15);
                    setFCMID(genFCMID);
                    Utilities.persistFCMID(genFCMID);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (appInitialized) {
            fetchMenuCategories();
        }
    }, [appInitialized])

    useEffect(() => {
        if (fcmID) {
            Utilities.isAppInitialized().then(response => {
                if (response) {
                    Utilities.isCustomerLoggedIn().then(response => {
                        setCustomerLogin(response)
                        setAppInitialized(2);
                    });
                } else {
                    ServerAPI.InitAnonymousUser(fcmID).then(response => {
                        if (response.success === true) {
                            Utilities.persistAnonymousUser(response.anonymous_user_id, response.anonymous_user_api_key).then(() => {
                                Utilities.initializeApp(fcmID);
                                setTimeout(() => setAppInitialized(1), 500);
                            });
                        } else {
                            showToastMsg('info', "Error Initializing The User");
                        }
                    })
                }
            })
        }
    }, [fcmID]);

    const registerFCM = () => {

        // Check if Notification Permission is available
        PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
                // Request permission to use push notifications
                // iOS will prompt user and return if they granted permission or not
                // Android will just grant without prompting
                PushNotifications.requestPermissions().then((res) => {
                    if (res.receive === 'denied') {
                        showToastMsg('error', 'Push Notification permission denied');
                    } else {
                        showToastMsg('info', 'Push Notification permission granted');
                        register();
                    }
                });
            } else {
                register();
            }
        });
    }

    const register = () => {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                setFCMID(token.value);
                Utilities.persistFCMID(token.value);
                //showToastMsg('info', 'Push registration success');
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                alert('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                //setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                //setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            }
        );
    }

    const fetchMenuCategories = () => {
        addProcessingRequests();
        ServerAPI.getMenuCategories().then((response) => {
            if (response.success) {
                setMenuCategories(response.menu_categories);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <>
            {appInitialized !== 0 && (
                <IonReactRouter>
                    <Menu />
                    <IonSplitPane contentId="main-router">
                        <IonRouterOutlet id="main-router">
                            <Route path={PageLinks.HOME} exact={true} component={Home} />
                            <Route path={PageLinks.CATEGORY + "/:id"} exact={true} component={Category} />
                            <Route path={PageLinks.PRODUCT_DETAILS + "/:id"} exact={true} component={ProductDetails} />
                            <Route path={PageLinks.PRODUCT_CUSTOMIZE} exact={true} component={ProductCustomize} />
                            <Route path={PageLinks.CART} exact={true} component={Cart} />
                            <Route path={PageLinks.CHECKOUT} exact={true} component={Checkout} />
                            <Route path={PageLinks.CHECKOUT_RESPONSE + "/:id"} exact={true} component={CheckoutResponse} />
                            <Route path={PageLinks.LOGIN} exact={true} component={Login} />
                            <Route path={PageLinks.MY_ORDERS} exact={true} component={MyOrders} />
                            <Route path={PageLinks.MY_PROFILE} exact={true} component={MyProfile} />
                            <Route path={PageLinks.MY_ADDRESS} exact={true} component={MyAddress} />
                            <Route path={PageLinks.CHANGE_PASSWORD} exact={true} component={ChangePassword} />
                            <Route path={PageLinks.MY_WISHLIST} exact={true} component={MyWishlist} />
                            <Route path={PageLinks.CONTACT} exact={true} component={Contact} />
                            <Route path={PageLinks.HELP_CENTER} exact={true} component={HelpCenter} />
                            <Route path={PageLinks.ORDER_DETAILS + "/:id"} exact={true} component={OrderDetails} />
                            <Route path={PageLinks.TESTIMONIALS} exact={true} component={Testimonials} />
                            <Route path={PageLinks.FORGOTPASSWORD} exact={true} component={ForgotPassword} />
                            <Route path={PageLinks.ABOUT} exact={true} component={Aboutus} />
                            <Route path={PageLinks.SHIPPING} exact={true} component={ShippingPolicy} />
                            <Route path={PageLinks.TERMS_CONDITIONS} exact={true} component={TermsAndConditions} />
                            <Route path={PageLinks.PRIVACY_POLICY} exact={true} component={PrivacyPolicy} />
                            <Route path={PageLinks.CANCELLATIONPOLICY} exact={true} component={CancellationPolicy} />
                            <Route path={PageLinks.HOWVENETZWORKS} exact={true} component={HowVenetzWorks} />
                            <Route path={PageLinks.HOWTOORDER} exact={true} component={HowToOrder} />
                            <Route path={PageLinks.HOWTOMEASURE} exact={true} component={HowToMeasure} />
                            <Route path={PageLinks.HOWTOINSTALL} exact={true} component={HowToInstall} />
                        </IonRouterOutlet>
                    </IonSplitPane>
                </IonReactRouter>
            )}
        </>
    )
}

export default AppNavigate;