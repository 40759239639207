import React from 'react';
import { IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRouterLink, IonRow } from '@ionic/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { RouteComponentProps, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { useAppStateAPI } from '../common/AppStateAPI';
import * as ServerAPI from '../common/ServerAPI';
import { APIData } from '../common/DataTypes';
import ProductListItem from '../components/products/ProductListItem';
import { PageLinks } from '../common/Constants';

import { Helmet } from 'react-helmet';

const Category: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {

    const location = useLocation();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [categoryURLName, setCategoryURLName] = useState('');
    const [products, setProducts] = useState<APIData.Product[]>([]);
    const [category, setCategory] = useState<APIData.Category>();

    useEffect(() => {
        if (match.params.id) {
            setCategoryURLName(match.params.id);
        } else {
            setCategoryURLName('');
        }
    }, [match]);

    useEffect(() => {
        if (location.pathname.startsWith(PageLinks.CATEGORY + '/')) {
            fetchProducts();
        }
    }, [categoryURLName, location.pathname]);

    const fetchProducts = () => {
        if (categoryURLName) {
            addProcessingRequests();
            ServerAPI.getProductList(categoryURLName).then((response) => {
                if (response.success) {
                    setProducts(response.products);
                    if (response.category) {
                        setCategory(response.category);
                    }
                } else if (response.message) {
                    showToastMsg('error', response.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const addToWishlist = (productID: number) => {
        addProcessingRequests();
        ServerAPI.addItemToWishlist(productID).then((response) => {
            if (response.success) {
                setProducts(prods => prods.map((product) => {
                    if (product.product_id === productID) {
                        return { ...product, wishlist_item_id: response.wishlist_item_id };
                    } else {
                        return product;
                    }
                }));
                showToastMsg('success', response.message);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const removeFromWishlist = (wishlistItemID: number) => {
        addProcessingRequests();
        ServerAPI.removeItemFromWishlist(wishlistItemID).then((response) => {
            if (response.success) {
                setProducts(prods => prods.map((product) => {
                    if (product.wishlist_item_id === wishlistItemID) {
                        return { ...product, wishlist_item_id: 0 };
                    } else {
                        return product;
                    }
                }));
                showToastMsg('success', response.message);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <>
            {category !== undefined && (
                <Helmet>‍
                    <title>Customized Ready to install Mosquito nets with frames | {category.category_name}</title>
                    <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                    <meta property='og:url' content={`https://www.venetz.in/cat/${category.category_url_name}`} /> 
                    <meta property='og:type' content='website' />
                    <meta property='og:title' content={category.category_meta_title} />
                    <meta property='og:description' content={category.category_meta_description} />
                    <meta property='og:site_name' content='VENETZ' />
                </Helmet>
            )}
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    {category !== undefined && (
                        <section className="page-banner">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <span className="round-shape"></span>
                                        <h2 className="banner-title">{category.category_name}</h2>
                                        <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / {category.category_name}</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                    <section className="checkout-section">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    {products.length > 0 ?
                                        <IonGrid>
                                            <IonRow>
                                                {products.map((product, index) => (
                                                    <IonCol size="12" size-md="4" size-lg="4" key={index}>
                                                        <ProductListItem product={product} addToWishlist={addToWishlist} removeFromWishlist={removeFromWishlist} />
                                                    </IonCol>
                                                ))}
                                            </IonRow>
                                        </IonGrid>
                                        :
                                        <h2>No Products Exists</h2>
                                    }
                                </div>
                                <div className="col-lg-12">
                                    {category !== undefined && (
                                        <>
                                            <p className='about_content'><img src={category.category_menu_image} /></p>
                                        </>
                                    )}
                                    <div className="testimonials-content">
                                        {category !== undefined && (
                                            <>
                                                <h2 className="sec-title">{category.category_description}</h2>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};

export default Category;
