import React from 'react';
import { useEffect, useState } from "react";


const StarRating: React.FC<{ rating: string | number }> = ({ rating }) => {

    const [ratingValue, setRatingValue] = useState<number>(0);

    useEffect(() => {
        if (rating !== null && typeof rating == 'string') {
            setRatingValue(parseFloat(rating));
        } else if (rating !== null && typeof rating == 'number') {
            setRatingValue(rating);
        }
    }, [rating]);

    return (
        <div className="stars">
            {
                [...Array(Math.floor(ratingValue))].map((value, index) => {
                    return (<i className="fa fa-star" key={'full-' + index}></i>)
                })
            }
            {
                [...Array(5 - Math.floor(ratingValue))].map((value, index) => {
                    if (index === 0 && (ratingValue - Math.floor(ratingValue)) > 0.2) {
                        if ((ratingValue - Math.floor(ratingValue)) < 0.75) {
                            return (<i className="fa fa-star-half-stroke" key={'rest-' + index}></i>)
                        } else {
                            return (<i className="fa fa-star" key={'rest-' + index}></i>)
                        }
                    } else {
                        return (<i className="fa fa-star" key={'rest-' + index}></i>)
                    }
                })
            }
        </div>
    )
}

export default StarRating;