import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAppStateAPI } from '../../common/AppStateAPI';
import { Configs } from '../../common/Constants';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AppToast: React.FC = () => {
    const { hideToastMsg, showToast, toastSeverity, toastMessage } = useAppStateAPI();

    const handleClose = () => {
        hideToastMsg();
    };


    return (
        <Snackbar open={showToast} autoHideDuration={Configs.ToastDuration} onClose={handleClose} sx={{ zIndex: 99999 }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity={toastSeverity} sx={{ width: '100%', fontSize: '16px' }}>
                {toastMessage}
            </Alert>
        </Snackbar>
    );
}

export default AppToast;