import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import StarRating from '../common/StarRating';
import { APIData } from '../../common/DataTypes';
import { useLocation } from 'react-router';
import * as ServerAPI from '../../common/ServerAPI';
import { useAppStateAPI } from '../../common/AppStateAPI';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const TestimonialSection: React.FC = () => {

    const location = useLocation();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [testimonials, setTestimonials] = useState<APIData.Testimonial[]>([]);

    useEffect(() => {
        fetchTestimonials();
    }, [location.pathname]);

    const fetchTestimonials = () => {
        addProcessingRequests();
        ServerAPI.getTestimonials().then((response) => {
            if (response.success) {
                setTestimonials(response.testimonials);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <div>
            <section className="popular-section white_bg">
                <div className="shape-round">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="sec-title">Trusted by 1000+ customers worldwide</h2>
                            <div className="testimonials-content">
                                <Swiper 
                                slidesPerView={3} 
                                loop={true} 
                                autoplay={true}
                                pagination={true}
                                >
                                    {testimonials.map((testimonial, index) => (
                                        <SwiperSlide key={index} className='testimonial_slider'>
                                            <div className="pp-single-slider">
                                                <div className="single-google-reviews">
                                                    <StarRating rating={testimonial.testimonial_star_rating} />
                                                    <div className="reviews_desc">{testimonial.testimonial_description}</div>
                                                    <div className="reviews_profile">
                                                        <div className="reviews_profile_img"><img src="assets/images/home/pro-cat.png" /></div>
                                                        <div className="reviews_profile_name">{testimonial.testimonial_customer_name}</div>
                                                        {/* <div className="reviews_profile_post_time">{Utilities.getDurationSince(testimonial.testimonial_datetime)}</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="white_bg insta_whole">
                <div className="container-fluid">
                    <div className="col-lg-12 col-md-12">
                        <div className="insta_whole_head">
                            <h4>@venetzmosquitonets</h4>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 float-left">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12" size-md="4" size-lg="4">
                                    <div className="insta_img">
                                        <img src="assets/images/home/insta1.png" />
                                    </div>
                                </IonCol>
                                <IonCol size="12" size-md="4" size-lg="4">
                                    <div className="insta_img">
                                        <img src="assets/images/home/insta2.png" />
                                    </div>
                                </IonCol>
                                <IonCol size="12" size-md="4" size-lg="4">
                                    <div className="insta_img">
                                        <img src="assets/images/home/insta3.png" />
                                    </div>
                                </IonCol>
                                <IonCol size="12" size-md="4" size-lg="4">
                                    <div className="insta_img">
                                        <img src="assets/images/home/insta4.png" />
                                    </div>
                                </IonCol>
                                <IonCol size="12" size-md="4" size-lg="4">
                                    <div className="insta_img">
                                        <img src="assets/images/home/insta5.png" />
                                    </div>
                                </IonCol>
                                <IonCol size="12" size-md="4" size-lg="4">
                                    <div className="insta_img">
                                        <img src="assets/images/home/insta6.png" />
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default TestimonialSection;