import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';

import { Helmet } from 'react-helmet';

const Aboutus: React.FC = () => {

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | About Us</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/about-us' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | About Us' />
                <meta property='og:description' content='VENETZ | About Us' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">About Us</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / About Us</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="popular-section white_bg">
                        <div className="shape-round">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="sec-title">WHO WE ARE</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>We are the leading H.D.P.E Mosquito net manufacturer in India. We have well established infrastructure with necessary machinery and manpower. Started in the year 1984 the company has gained its extensive experience and excel in its area of operations. Its impeccable quality products have earned the company a favourable reputation and reliability. As a result of the fine techniques and means of production followed by the organization, our company has climbed the ladder of success in the world market.</p>
                                        <p className='about_content'>To meet out the high quality standards, the mandatory quality check is done at the end of every single stage of our production process.Most areas within India where mosquito borne diseases like malaria, dengue fever, Chikungunya, zika fever, yellow fever and more, are a constant concern, and where a mosquito net can literally save your life, I greatly value having high quality mosquito nets.</p>
                                        <p className='about_content'>Generally for healthy people, the viral fever is usually not life threatening but I can vouch for the fact that it is miserable and definitely something to avoid!</p>
                                        <p className='about_content'>With climate change, the range and population of mosquitoes is shifting and is already resulting in longer mosquito seasons, larger mosquito populations, and mosquitoes are now able to live in areas that were previously too cool.  With all of these changes comes increased risk for catching a mosquito borne disease.</p>
                                        <p className='about_content'>After closely following the expansion of the different epidemic virus, I decided to start a line of high quality mosquito nets that can be used inside and outside to help people stay safe and healthy by keeping mosquitoes and their potentially dangerous bites away.</p>
                                        <p className='about_content'>Check out the Venetz.in for important mosquito borne disease information and news as well as fun and practical mosquito net tips and tricks.</p>
                                        <p className='about_content'>Protect those you love!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default Aboutus;
