import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MyAccountLeftSidebar from './MyAccountLeftSidebar';
import { PageLinks } from '../common/Constants';
import { useLocation } from 'react-router';
import { APIData } from '../common/DataTypes';
import * as Utilities from '../common/Utilities';
import * as ServerAPI from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';

import { Helmet } from 'react-helmet';

const MyAccount: React.FC = () => {

    const location = useLocation();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [orders, setOrders] = useState<APIData.Order[]>([]);

    useEffect(() => {
        if (location.pathname == PageLinks.MY_ORDERS) {
            fetchOrders();
        }
    }, [location.pathname]);

    const fetchOrders = () => {
        addProcessingRequests();
        ServerAPI.getOrders().then((response) => {
            if (response.success) {
                setOrders(response.orders);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | My Orders</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/change-password' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | My Orders' />
                <meta property='og:description' content='VENETZ | My Orders' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen>
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">My Account</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / My Account</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="blog-section pt-5">
                        <div className="container-fluid">
                            <div className="row">
                                <MyAccountLeftSidebar />
                                <div className="col-lg-10 col-md-9">
                                    {orders.length === 0 && (
                                        <div className="row text-center" id="cartIsEmpty">
                                            <div className="page-404 shopping-cart left orders col-lg-12">
                                                <h2 className="mb-50">Oops ! Orders is empty.</h2>
                                                <div className="text">Your Orders is empty.. <IonRouterLink routerLink={PageLinks.HOME}>Click here</IonRouterLink> to continue shopping.</div>
                                            </div>
                                        </div>
                                    )}
                                    {orders.length > 0 && (
                                        <div id="customerMyAccountSectionOrders">
                                            {orders.map((order, index) => (
                                                <div id="order-section" key={index}>
                                                    <div className="order">
                                                        <div className="order-collapsed">
                                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 no_pad float-left">Order No : <strong><IonRouterLink routerLink={PageLinks.ORDER_DETAILS + '/' + order.order_id} className='bread-crumb-text'>{order.order_reference_number}</IonRouterLink></strong> </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 no_pad float-left"> Order Total : <strong><i className="fa fa-inr"></i> {order.order_total_amount}</strong> </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 no_pad float-left"> Date Placed : <strong>{Utilities.getFormattedLocalDateTime(order.order_add_time)}</strong> </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 no_pad float-left"> Order Status : <span className="cancelled" style={{ background: `${Utilities.orderStatusBackground(order.order_status)}`, border: `2px solid ${Utilities.orderStatusBackground(order.order_status)}` }}>{Utilities.getOrderStatusString(order.order_status)}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default MyAccount;
