import { useAppState, StateActionTypes } from "./AppState";
import { AlertType } from "./Constants";
import { APIData } from "./DataTypes";

export const useAppStateAPI = () => {
    const { state, dispatch } = useAppState();

    return {
        showSuccessPopup: state.showSuccessPopup,
        successTitle: state.successTitle,
        successMessage: state.successMessage,
        setShowSuccessPopup: (showSuccessPopup: boolean, successTitle?: string, successMessage?: string) => dispatch({ type: StateActionTypes.SET_SHOW_SUCCESS_POPUP, showSuccessPopup: showSuccessPopup, successTitle: successTitle, successMessage: successMessage }),

        processingRequests: state.processingRequests,
        addProcessingRequests: () => dispatch({ type: StateActionTypes.ADD_PROCESSING_REQUESTS }),
        reduceProcessingRequests: () => dispatch({ type: StateActionTypes.REDUCE_PROCESSING_REQUESTS }),

        showToast: state.showToast,
        toastSeverity: state.toastSeverity,
        toastMessage: state.toastMessage,
        showToastMsg: (toastSeverity: AlertType, toastMessage: string) => dispatch({ type: StateActionTypes.SET_SHOW_TOAST, showToast: true, toastSeverity: toastSeverity, toastMessage: toastMessage }),
        hideToastMsg: () => dispatch({ type: StateActionTypes.SET_SHOW_TOAST, showToast: false, toastSeverity: 'info', toastMessage: "" }),

        customerLogin: state.customerLogin,
        setCustomerLogin: (customerLogin: boolean) => dispatch({ type: StateActionTypes.SET_CUSTOMER_LOGIN, customerLogin: customerLogin }),

        cartCount: state.cartCount,
        setCartCount: (cartCount: number) => dispatch({ type: StateActionTypes.SET_CART_COUNT, cartCount: cartCount }),

        menuCategories: state.menuCategories,
        setMenuCategories: (menuCategories: APIData.Category[]) => dispatch({ type: StateActionTypes.SET_MENU_CATEGORIES, menuCategories: menuCategories }),

        loginRedirectLink: state.loginRedirectLink,
        setCustomerLoginRedirectLink: (loginRedirectLink: string) => dispatch({ type: StateActionTypes.SET_LOGIN_REDIRECT, loginRedirectLink: loginRedirectLink }),
    }
}