import { Preferences } from '@capacitor/preferences';
import moment from 'moment';
import { DateFormats, OrderStatuses } from './Constants';

export const initializeApp = async (fcmID: string) => {
    await Preferences.set({ key: 'init', value: "1" });
    await Preferences.set({ key: 'login', value: "0" });
    await Preferences.set({ key: 'fcm_id', value: fcmID });
}

export const persistFCMID = async (fcmID: string) => {
    await Preferences.set({ key: 'fcm_id', value: fcmID });
}

export const getPersistedFCMID = async () => {
    const init = await Preferences.get({ key: 'fcm_id' });
    return init.value !== null ? init.value : false;
}

export const persistAnonymousUser = async (userID: number, apiKey: string) => {
    await Preferences.set({ key: 'anonymous_user_id', value: userID + "" });
    await Preferences.set({ key: 'anonymous_user_api_key', value: apiKey });
}

export const persistCustomerLogin = async (customerID: number, customerAPIKey: string) => {
    await Preferences.set({ key: 'login', value: "1" });
    await Preferences.set({ key: 'customer_id', value: customerID + "" });
    await Preferences.set({ key: 'customer_api_key', value: customerAPIKey });
    // await Preferences.set({ key: 'customer_first_name', value: customerFirstName });
    // await Preferences.set({ key: 'customer_last_name', value: customerLastName });
    // await Preferences.set({ key: 'customer_email', value: customerEmail });
    // await Preferences.set({ key: 'customer_mobile', value: customerMobile });

}

// export const updateCustomerLogin = async (customerFirstName: string, customerLastName: string, customerEmail: string, customerMobile: string) => {
//     await Preferences.set({ key: 'customer_first_name', value: customerFirstName });
//     await Preferences.set({ key: 'customer_last_name', value: customerLastName });
//     await Preferences.set({ key: 'customer_email', value: customerEmail });
//     await Preferences.set({ key: 'customer_mobile', value: customerMobile });
// }

export const updateCustomerAPIKey = async (apiKey: string) => {
    await Preferences.set({ key: 'customer_api_key', value: apiKey });
}

export const removeCustomerLogin = async () => {
    await Preferences.set({ key: 'login', value: "0" });
    await Preferences.remove({ key: 'customer_id' });
    await Preferences.remove({ key: 'customer_api_key' });
    // await Preferences.remove({ key: 'customer_first_name' });
    // await Preferences.remove({ key: 'customer_last_name' });
    // await Preferences.remove({ key: 'customer_email' });
    // await Preferences.remove({ key: 'customer_mobile' });
}

export const getCustomerProfileData = async () => {
    const customer_first_name = await Preferences.get({ key: 'customer_first_name' });
    const customer_last_name = await Preferences.get({ key: 'customer_last_name' });
    const customer_email = await Preferences.get({ key: 'customer_email' });
    const customer_mobile = await Preferences.get({ key: 'customer_mobile' });

    return { customer_first_name: customer_first_name.value ? customer_first_name.value : '', customer_last_name: customer_last_name.value ? customer_last_name.value : '', customer_email: customer_email.value ? customer_email.value : '', customer_mobile: customer_mobile.value ? customer_mobile.value : '' }
}

export const isAppInitialized = async () => {
    const init = await Preferences.get({ key: 'init' });
    return init.value !== null ? true : false;
}

export const isCustomerLoggedIn = async () => {
    const login = await Preferences.get({ key: 'login' });
    return login.value === "1" ? true : false;
}

export const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const isValidIndianMobileNumber = (mobileNumber: string): boolean => {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(mobileNumber);
}

export const isValidIndianPincode = (pincode: string): boolean => {
    // Regular expression to match a valid Indian PIN code
    const pincodeRegex = /^[1-9]\d{5}$/;
    return pincodeRegex.test(pincode);
}

export const isStringValidNumber = (value: string): boolean => {
    // Regular expression to test if the entire string is a valid number (integer or floating-point)
    const regex = /^-?\d+\.?\d*$/;
    return regex.test(value.trim());
}

export const formatIndianCurrency = (num: number): string => {
    return num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR'
    });
}

export const formatIndianNumber = (num: number): string => {
    return num.toLocaleString('en-IN');
}

export const getFormattedLocalDateTime = (dateTime: string, dateFormat = DateFormats.DDMONYYYY, timeFormat = 'hh:mm a') => {
    if (dateTime) {
        return moment(dateTime).format(dateFormat + ' ' + timeFormat);
    }
    return 'N/A';
}

export const getFormattedLocalTime = (dateTime: string, timeFormat = 'hh:mm a') => {
    if (dateTime) {
        return moment(dateTime).format(timeFormat);
    }
    return 'N/A';
}

export const getDurationSince = (dateString: string): string => {
    const date = moment(dateString);
    const now = moment();

    const years = now.diff(date, 'years');
    date.add(years, 'years');

    const months = now.diff(date, 'months');
    date.add(months, 'months');

    const days = now.diff(date, 'days');
    date.add(days, 'days');

    const hours = now.diff(date, 'hours');
    date.add(hours, 'hours');

    const minutes = now.diff(date, 'minutes');
    date.add(minutes, 'minutes');

    const seconds = now.diff(date, 'seconds');

    if (years > 0) {
        return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
        return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
        return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
}

export const generateRandomString = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const getAuthString = async () => {
    let basicAuthString = '';

    const login = await Preferences.get({ key: 'login' });

    let authID = null;
    let authAPIKey = null;
    if (login.value === '1') {
        authID = await Preferences.get({ key: 'customer_id' });
        authAPIKey = await Preferences.get({ key: 'customer_api_key' });
    } else if (login.value === '0') {
        authID = await Preferences.get({ key: 'anonymous_user_id' });
        authAPIKey = await Preferences.get({ key: 'anonymous_user_api_key' });
    }
    if (authID !== null && authID.value !== null && authAPIKey !== null && authAPIKey.value !== null) {
        const authAppNum = (login.value == '0' ? '1' : '2');
        basicAuthString = "Basic " + (window.btoa(authID.value + "#" + authAppNum + ":" + authAPIKey.value));
        return basicAuthString;
    }
}

export const getOrderStatusString = (status: number) => {
    let returnString = "";
    if (status == OrderStatuses.Failed) {
        returnString = 'Failed';
    } else if (status == OrderStatuses.Cancelled) {
        returnString = 'Cancelled';
    } else if (status == OrderStatuses.Pending) {
        returnString = 'Pending';
    } else if (status == OrderStatuses.ConfirmationPending) {
        returnString = 'Confirmation Pending';
    } else if (status == OrderStatuses.Confirmed) {
        returnString = 'Confirmed';
    } else if (status == OrderStatuses.Packed) {
        returnString = 'Packed';
    } else if (status == OrderStatuses.Shipped) {
        returnString = 'Shipped';
    } else if (status == OrderStatuses.Delivered) {
        returnString = 'Delivered';
    } else if (status == OrderStatuses.Completed) {
        returnString = 'Completed';
    }
    return returnString;
}

export const orderStatusBackground = (status: number) => {
    let returnString = '';
    if (status == OrderStatuses.Failed) {
        returnString = '#e20000';
    } else if (status == OrderStatuses.Cancelled) {
        returnString = '#b1b1b1';
    } else if (status == OrderStatuses.Pending) {
        returnString = '#ffd800';
    } else {
        returnString = '#5cb85c';
    }
    return returnString;
}