import React, { useEffect, useRef } from "react";

const OTPInput: React.FC<{ otp: string, setOTP: React.Dispatch<React.SetStateAction<string>>, otpLength: number }> = ({ otp, setOTP, otpLength }) => {

    const inputRefs = useRef<HTMLInputElement[]>([]);

    // Add or replace refs in the array
    const addRef = (el: HTMLInputElement, index: number) => {
        inputRefs.current[index] = el;
    };

    // Effect to focus next input after state update
    useEffect(() => {
        if (otp.length < otpLength && inputRefs.current[otp.length]) {
            inputRefs.current[otp.length].focus();
        }
    }, [otp]);

    const handleChange = (element: HTMLInputElement, index: number) => {
        const value = element.value;
        setOTP(prev => prev.substring(0, index) + value + prev.substring(index + 1));
    };

    return (
        <div className="inputs_container">
            <div id="inputs" className="inputs">
                <label>Enter OTP</label>
                {[...Array(otpLength)].map((_, index) => (
                    <input
                        key={index}
                        ref={el => addRef(el as HTMLInputElement, index)}
                        className="input"
                        type="text"
                        inputMode="numeric"
                        maxLength={1}
                        value={otp[index] || ''}
                        onChange={(e) => handleChange(e.target as HTMLInputElement, index)}
                    />
                ))}
            </div>
        </div>
    )
}

export default OTPInput;