import { IonRouterLink } from '@ionic/react';
import React from 'react';
import { PageLinks } from '../common/Constants';
import * as Utilities from '../common/Utilities';
import { useAppStateAPI } from '../common/AppStateAPI';
import { useHistory } from 'react-router';

const MyAccountLeftSidebar: React.FC = () => {

    const history = useHistory();
    const { setCustomerLogin } = useAppStateAPI();

    const handleLogout = () => {
        Utilities.removeCustomerLogin().then(() => {
            setCustomerLogin(false);
            //App.exitApp();
            history.replace(PageLinks.HOME);
            //history.push(PageLinks.DASHBOARD);
        })
    }

    return (
        <div className="col-lg-2 col-md-3">
            <div className="blog-sidebar bs-left">
                <aside className="widget">
                    <h3 className="widget-title">My Account</h3>
                    <ul>
                        <li><IonRouterLink routerLink={PageLinks.MY_ORDERS} className='bread-crumb-text'>My Orders</IonRouterLink></li>
                        <li><IonRouterLink routerLink={PageLinks.MY_PROFILE} className='bread-crumb-text'>My Profile</IonRouterLink></li>
                        <li><IonRouterLink routerLink={PageLinks.MY_ADDRESS} className='bread-crumb-text'>My Address</IonRouterLink></li>
                        <li><IonRouterLink routerLink={PageLinks.CHANGE_PASSWORD} className='bread-crumb-text'>Change Password</IonRouterLink></li>
                        <li><IonRouterLink routerLink={PageLinks.MY_WISHLIST} className='bread-crumb-text'>My Wishlist</IonRouterLink></li>
                        <li><a href="#" className='bread-crumb-text' onClick={e => { e.preventDefault(); handleLogout(); }}>Logout</a></li>
                    </ul>
                </aside>
            </div>
        </div>
    );
};
export default MyAccountLeftSidebar;
