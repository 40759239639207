import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import { IonCol, IonGrid, IonRouterLink, IonRow } from '@ionic/react';
import { APIData } from '../../common/DataTypes';
// const config = {
//     delay: 9000,
//     startwidth: 1170,
//     startheight: 500,
//     hideThumbs: 10,
//     fullWidth: "on",
//     forceFullWidth: "on"
// };

const HomeSlider: React.FC<{ sliderImages: APIData.DynamicImage[] }> = ({ sliderImages }) => {

    return (
        <div>
            <section className="slider-01">
                <div className="scroll-down"><IonRouterLink routerLink="#coupone" className='left_side_head'>scroll to explore</IonRouterLink><img src="assets/images/home/scroll.png" alt="" /></div>
                <div className="rev_slider_wrapper">
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        autoplay={{
                            delay: 500,
                            disableOnInteraction: false
                        }}
                    >
                        {sliderImages.map((image, index) => (
                            <SwiperSlide key={index}>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12" size-md="12" size-lg="12" className='ion-text-center slider_right'>
                                            <img src={image.dynamic_image_path} />
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
        </div>
    );
};

export default HomeSlider;


