import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';

import { Helmet } from 'react-helmet';

const CancellationPolicy: React.FC = () => {

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | CancellationPolicy</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/shipping-policy' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | CancellationPolicy' />
                <meta property='og:description' content='VENETZ | CancellationPolicy' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">Cancellation & Refund Policy</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Shipping & Return Policy</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="popular-section white_bg">
                        <div className="shape-round">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="testimonials-content">
                                        <p className='privacy_com_content'>
                                            LEGAL NAME : VIMAL NETS   <br />
                                            TRADE NAME : VENETZ
                                            <br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="sec-title">Cancellation Processing Time</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>Cancellations will be processed within *2 business days* from the date we receive the returned product in its original, unused condition along with all accessories, documentation, and packaging.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-20">
                                    <h2 className="sec-title">Refund Policy</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>Refunds will be initiated after the cancellation is processed and the product is inspected. The refund will be credited to the original payment method within *7-10 business days* depending on the banks policies.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-20">
                                    <h2 className="sec-title">Conditions for Return</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>The item must be returned in its original condition, without any damage, and with all original packaging, tags, and accessories.</p>
                                        <p className='about_content'>
                                            Returns must be initiated within the period specified in our return policy, subject to conditions  as per the companys stated return period.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-20">
                                    <h2 className="sec-title">Non-Returnable Items</h2>
                                    <div className="testimonials-content">
                                        <p className='about_content'>Customized Mosquito Nets: Please note that customized mosquito nets are non-returnable and cannot be exchanged or refunded. Since these products are made specifically to your requirements, we are unable to accept returns or process refunds once the order has been placed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default CancellationPolicy;
