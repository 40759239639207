import React, { useEffect, useState } from "react";
import * as Utilities from '../../common/Utilities';

const EstimatedDeliveryText: React.FC<{ estimatedDeliveryDate: string, cutoffTime: string, nextCutoffTime: string }> = ({ estimatedDeliveryDate, cutoffTime, nextCutoffTime }) => {

    const calculateTimeLeft = () => {
        let difference = +new Date(cutoffTime) - +new Date();
        if (difference <= 0) {
            difference = +new Date(nextCutoffTime) - +new Date();
        }
        let timeLeft = { hours: 0, minutes: 0, seconds: 0 };

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 10000);

        return () => clearInterval(timer);
    }, []);

    return (
        <>
            {Utilities.getFormattedLocalDateTime(estimatedDeliveryDate, 'dddd, Do MMMM.', '')} Order within <span className="colored">{timeLeft.hours} hrs {timeLeft.minutes} mins.</span>
        </>
    )
}

export default EstimatedDeliveryText;