import { IonContent, IonHeader, IonPage, IonRouterLink, useIonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MyAccountLeftSidebar from './MyAccountLeftSidebar';
import { PageLinks } from '../common/Constants';
import * as ServerAPI from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';
import { APIData } from '../common/DataTypes';
import AddressEntry from '../components/modals/AddressEntry';

import { Helmet } from 'react-helmet';

const MyAddress: React.FC = () => {

    const [presentAlert] = useIonAlert();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests, customerLogin } = useAppStateAPI();

    const [addresses, setAddresses] = useState<APIData.Address[]>([]);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [editAddressID, setEditAddressID] = useState(0);

    useEffect(() => {
        fetchAddresses();
    }, []);

    const fetchAddresses = () => {
        if (customerLogin) {
            addProcessingRequests();
            ServerAPI.getCustomerAddresses().then((response) => {
                if (response.success) {
                    setAddresses(response.customer_addresses);
                } else if (response.message) {
                    showToastMsg('error', response.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const deleteAddress = (addressID: number) => {
        addProcessingRequests();
        ServerAPI.deleteCustomerAddress(addressID).then((response) => {
            if (response.success) {
                showToastMsg('success', response.message);
                fetchAddresses();
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | My Address</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/my-address' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | My Address' />
                <meta property='og:description' content='VENETZ | My Address' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">My Account</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / My Account / My Address</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="blog-section pt-5">
                        <div className="container-fluid">
                            <div className="row">
                                <MyAccountLeftSidebar />
                                <div className="col-lg-9 col-md-7">
                                    <h3 className="sec-title">My Address</h3>
                                    <div className="register-form">
                                        <div className="woocommerce-billing-fields">
                                            <section className="error-section">
                                                <div className="container-fluid">
                                                    {addresses.length === 0 && (
                                                        <div className="row text-center" id="cartIsEmpty">
                                                            <div className="page-404 shopping-cart left orders col-lg-12">
                                                                <h2 className="mb-50">Oops ! Address is empty.</h2>
                                                                <div className="text">You Not added any Address.. <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowAddressModal(true);
                                                                }}>Click here</a> to Add Address.</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-lg-8 offset-lg-2">
                                                            <div className="content-404 text-center">
                                                                {/* {addresses.length === 0 && (
                                                                <h2>No Address Found...</h2>
                                                            )} */}
                                                                <a className="goru-btn" onClick={() => setShowAddressModal(true)}>Add Address</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <AddressEntry showModal={showAddressModal} setShowModal={setShowAddressModal} addressID={editAddressID} successCallBack={fetchAddresses} />
                                            {addresses.length > 0 && (
                                                <div className="" id="myaddress">
                                                    <ul className="wc_payment_methods payment_methods methods">
                                                        {addresses.map((address, index) => (
                                                            <li className="wc_payment_method payment_method_bacs" key={index}>
                                                                {/* <input checked value="1" name="selected_address" className="input-radio" id="selected_address" type="radio" /> */}
                                                                <label htmlFor="payment_method_bacs">{address.customer_address_full_name} <a onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setEditAddressID(address.customer_address_id);
                                                                    setShowAddressModal(true);
                                                                }} href='#'><i className='fa fa-edit'></i></a> <a href='#' onClick={e => {
                                                                    e.preventDefault();
                                                                    presentAlert({
                                                                        header: 'Remove Address',
                                                                        message: 'Are you sure you want to remove this address?',
                                                                        buttons: [{ text: 'OK', role: 'confirm' }, 'Cancel'],
                                                                        onDidDismiss: ({ detail }) => {
                                                                            if (detail.role === 'confirm') {
                                                                                deleteAddress(address.customer_address_id);
                                                                            }
                                                                        }
                                                                    });
                                                                }}><i className='fa fa-trash'></i></a></label>
                                                                <div className="payment_box payment_method_bacs visibales">
                                                                    {address.customer_address_line1 !== '' && (<p>{address.customer_address_line1},</p>)}
                                                                    {address.customer_address_line2 !== '' && (<p>{address.customer_address_line2},</p>)}
                                                                    <p>{address.customer_address_city}</p>
                                                                    <p>{address.state_name} - {address.customer_address_postal_code}</p>
                                                                    <p>Mobile : {address.customer_address_phone}</p>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default MyAddress;
