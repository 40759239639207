import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useHistory, useLocation } from 'react-router';

// import { HashScroll } from "react-hash-scroll";
import { AttributeTypeDisplayType, PageLinks, ProductImageType } from '../common/Constants';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import { useAppStateAPI } from '../common/AppStateAPI';
import { APIData } from '../common/DataTypes';
import * as ServerAPI from '../common/ServerAPI';
import * as Utilities from '../common/Utilities';
import EstimatedDeliveryText from '../components/products/EstimatedDeliveryText';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Helmet } from 'react-helmet';

function HowToMeasureModal(props: any) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>How To Measure?</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/XqcM8nwCm7o?controls=1&autoplay=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} className='close-btn'>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const ProductCustomize: React.FC = () => {

    const location = useLocation();
    const history = useHistory();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests, setCartCount } = useAppStateAPI();
    const [productDetail, setProductDetail] = useState<APIData.ProductDetail>();
    const [selectedVariant, setSelectedVariant] = useState<APIData.ProductVariant>();
    const [attributeTypeCount, setAttributeTypeCount] = useState(0);
    const [productQuantity, setProductQuantity] = useState(1);
    const [selectedAttributeValues, setSelectedAttributeValues] = useState<APIData.AttributeValue[]>([]);
    const [productImages, setProductImages] = useState<APIData.ProductImage[]>([]);
    const theme = useTheme();
    const [tabValue, setTabValue] = React.useState(0);
    const [postalCode, setPostalCode] = useState('');
    const [deliveryAvailable, setDeliveryAvailable] = useState<'available' | 'unavailable' | ''>('');
    const [length, setLength] = useState('');
    const [breadth, setBreadth] = useState('');
    const [height, setHeight] = useState('');
    const [productCustomSize, setProductCustomSize] = useState<APIData.ProductCustomSize>();
    const [estimatedDelivery, setEstimatedDelivery] = useState<APIData.EstimatedDelivery>();
    const videoSectionRef = useRef<HTMLDivElement>(null);
    const [hidePeacefulSleep, setHidePeacefulSleep] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (location.pathname === PageLinks.PRODUCT_CUSTOMIZE) {
            fetchProductDetail();
            setHidePeacefulSleep(false);
            setTabValue(0);
            setLength('');
            setBreadth('');
            setHeight('');
            setProductCustomSize(undefined);
        }
    }, [location.pathname]);

    const fetchProductDetail = () => {
        addProcessingRequests();
        ServerAPI.getCustomizationProductDetail().then((response) => {
            if (response.products.length > 0) {
                setProductDetail(response.products[0]);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const checkDeliveryAvailability = () => {
        if (!postalCode) {
            showToastMsg('error', 'Please enter Pincode');
        } else if (!Utilities.isValidIndianPincode(postalCode)) {
            showToastMsg('error', 'Please enter valid Pincode');
        } else {
            addProcessingRequests();
            ServerAPI.deliveryCheck(postalCode).then((response) => {
                if (response.success) {
                    if (response.delivery_available) {
                        setDeliveryAvailable('available');
                    } else {
                        setDeliveryAvailable('unavailable');
                    }
                } else if (response.message) {
                    showToastMsg('error', response.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    useEffect(() => {
        setDeliveryAvailable('');
    }, [postalCode]);

    useEffect(() => {
        if (productDetail !== undefined) {
            if (productDetail.product_variants.length > 0) {
                if (productDetail.product_has_attributes) {
                    setSelectedVariant(productDetail.product_variants[0]);
                }
                setAttributeTypeCount(productDetail.product_variants[0].product_variant_attrib_values.length);
                const selectedAttrbVals: APIData.AttributeValue[] = [];
                productDetail.product_variants[0].product_variant_attrib_values.forEach(prodVariantAttribVal => {
                    productDetail.product_attribute_types.forEach(attribType => {
                        attribType.product_attribute_values.forEach(attribVal => {
                            if (attribVal.attribute_value_id == prodVariantAttribVal.attribute_value_id) {
                                selectedAttrbVals.push(attribVal);
                            }
                        })
                    })
                })
                setSelectedAttributeValues(selectedAttrbVals);
            }
            if (!productDetail.product_has_attributes || productDetail.product_image_type === ProductImageType.ProductBased) {
                setProductImages(productDetail.product_images);
            }
        }

    }, [productDetail]);

    useEffect(() => {
        if (productDetail !== undefined) {
            const selAttributeValIDs = selectedAttributeValues.map(val => val.attribute_value_id);
            productDetail.product_variants.forEach(variant => {
                const matchedValues = variant.product_variant_attrib_values.filter(val => selAttributeValIDs.includes(val.attribute_value_id));
                if (matchedValues.length === attributeTypeCount) {
                    setSelectedVariant(variant);
                }
            })
        }
    }, [selectedAttributeValues]);

    useEffect(() => {
        if (productDetail !== undefined && selectedVariant) {
            let images: APIData.ProductImage[] = [];
            if (productDetail.product_image_type === ProductImageType.VariantBased) {
                images = productDetail.product_images.filter(image => image.product_variant_id === selectedVariant.product_variant_id)
                setProductImages(images);
            } else if (productDetail.product_image_type === ProductImageType.AttributeBased) {
                const imgAttributeTypeValues = selectedAttributeValues.filter(attribVal => attribVal.attribute_type_id === productDetail.product_image_attribute_type_id);
                if (imgAttributeTypeValues.length) {
                    images = productDetail.product_images.filter(image => image.attribute_value_id === imgAttributeTypeValues[0].attribute_value_id);
                }
                setProductImages(images);
            }
            /*let mainProductPrice: number = parseFloat(selectedVariant.product_variant_selling_price);
            if (productDetail.addon_products !== undefined && productDetail.addon_products.length > 0) {
                productDetail.addon_products.forEach(addonProduct => {
                    if (addonProduct.product_variants.length > 0) {
                        mainProductPrice += parseFloat(addonProduct.product_variants[0].product_variant_selling_price);
                    }
                })
            }
            setFinalPrice(mainProductPrice);*/
        }

    }, [selectedVariant]);

    const updateProductQuantity = (reduce?: boolean) => {
        if (reduce && productQuantity > 1) {
            setProductQuantity(currQty => currQty - 1);
        }
        if (!reduce && productQuantity < 10) {
            setProductQuantity(currQty => currQty + 1);
        }
    }

    const updateSelectedAttributeValue = (newAttribValue: APIData.AttributeValue) => {
        const updatedAttribValues = selectedAttributeValues.map(attribValue => {
            if (attribValue.attribute_type_id === newAttribValue.attribute_type_id) {
                return newAttribValue;
            } else {
                return attribValue;
            }
        })
        setSelectedAttributeValues(updatedAttribValues);
    }

    const fetchProductCustomSize = () => {
        if (!length || !Utilities.isStringValidNumber(length)) {
            showToastMsg('error', 'Enter Valid Length Measurement');
        } else if (!breadth || !Utilities.isStringValidNumber(breadth)) {
            showToastMsg('error', 'Enter Valid Breadth Measurement');
        } else if (!height || !Utilities.isStringValidNumber(height)) {
            showToastMsg('error', 'Enter Valid Height Measurement');
        } else {
            addProcessingRequests();
            ServerAPI.getProductCustomSize(parseFloat(length), parseFloat(breadth), parseFloat(height)).then((response) => {
                if (response.success > 0) {
                    setProductCustomSize({ product_custom_size_name: response.product_custom_size_name, product_custom_size_price: response.product_custom_size_price });
                    setEstimatedDelivery({ estimated_delivery_date: response.estimated_delivery_date, order_process_cutoff_time: response.order_process_cutoff_time, order_process_next_cutoff_time: response.order_process_next_cutoff_time });
                } else if (response.message) {
                    showToastMsg('error', response.message);
                    setProductCustomSize(undefined);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                    setProductCustomSize(undefined);
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const addToCart = (checkout?: boolean) => {
        if (productDetail && selectedVariant && productQuantity) {
            addProcessingRequests();
            ServerAPI.addCustomizedItemToCart(selectedVariant.product_variant_id, productQuantity, parseFloat(length), parseFloat(breadth), parseFloat(height)).then((response) => {
                if (response.success) {
                    if (response.cart_count !== undefined) {
                        setCartCount(response.cart_count);
                    }
                    if (checkout) {
                        history.push(PageLinks.CHECKOUT);
                    } else {
                        history.push(PageLinks.CART);
                    }
                    showToastMsg('success', response.message);
                } else {
                    showToastMsg('error', 'Could not add item to cart');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    return (
        <>
            {productDetail !== undefined && (
                <Helmet>‍
                    <title>Customized Ready to install Mosquito nets with frames | Customize Your Mosquito Net</title>
                    <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                    <meta property='og:url' content='https://www.venetz.in/product-customize' />
                    <meta property='og:type' content='website' />
                    <meta property='og:title' content={productDetail.product_meta_title} />
                    <meta property='og:description' content={productDetail.product_meta_description} />
                    <meta property='og:site_name' content='VENETZ' />
                </Helmet>
            )}
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <div className="bread-crumb pb-4"><IonRouterLink routerLink={PageLinks.HOME} className='customize-bread-crumb-text'>Home / </IonRouterLink>Mosquito Net For King Size Bed  / Customize</div>
                                    <h2 className="banner-title">Customize Your Net</h2>
                                    <p className='customize_sub_text'>Best Mosquito net for bed with stand to prevent from mosquito bites.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="single-product-section">
                        <div className="container-fluid">
                            <div className="row">
                                {productDetail !== undefined && (
                                    <div className="col-lg-12 col-md-12 col-12 product_nets_tab">
                                        <Box sx={{ width: '100%' }}>
                                            <Tabs
                                                value={tabValue}
                                                //onChange={handleTabChange}
                                                indicatorColor="primary"
                                                textColor="inherit"
                                                aria-label="full width tabs example"
                                                variant="scrollable"
                                                scrollButtons
                                                allowScrollButtonsMobile
                                            >
                                                <Tab label="Delivery Availability Checker" icon={<LooksOneOutlinedIcon />} iconPosition="start" />
                                                {/* <span className='tab_head_icon'><i className="fa fa-long-arrow-right"></i></span> */}
                                                <Tab label="Choose Color" icon={<LooksTwoOutlinedIcon />} iconPosition="start" />
                                                {/* <span className='tab_head_icon'><i className="fa fa-long-arrow-right"></i></span> */}
                                                <Tab label="Enter Dimension" icon={<Looks3OutlinedIcon />} iconPosition="start" />
                                            </Tabs>
                                            <TabPanel value={tabValue} index={0} dir={theme.direction}>
                                                <div className='dimension_tab step_check_availability'>
                                                    <div className="product-color">
                                                        <h5>Check Delivery Availability</h5>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="Enter Pincode *" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                                                            <div className="input-group-append product-calculate">
                                                                <button className="add-to-cart-btn" type="button" onClick={checkDeliveryAvailability}>
                                                                    CHECK
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {/* <span className='change_pincode_text'><IonRouterLink className='change_pincode' href='#'>Change pincode</IonRouterLink></span> */}
                                                        {deliveryAvailable === 'available' && (
                                                            <div className='cod_avilability_text available'>Delivery Available For Your Location</div>
                                                        )}
                                                        {deliveryAvailable === 'unavailable' && (
                                                            <div className='cod_avilability_text not_available'>Delivery Not Available For Your Location </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <p className='cod_avilability_text not_available_msg'>Please Call Us On <strong>+91 95855 20356</strong> To Help You With Delivery.</p>
                                                <div className="next-prev">
                                                    <a href='#' className="add-to-cart-btn next_btn" onClick={e => {
                                                        e.preventDefault();
                                                        if (!postalCode) {
                                                            showToastMsg('error', 'Enter your Pincode and Check for Delivery Availability before Proceeding');
                                                        } else if (!Utilities.isValidIndianPincode(postalCode)) {
                                                            showToastMsg('error', 'Please enter a valid Pincode and Check for Delivery Availability before Proceeding');
                                                        } else if (!deliveryAvailable) {
                                                            showToastMsg('error', 'Please check for Delivery Availability');
                                                        } else if (deliveryAvailable === 'unavailable') {
                                                            showToastMsg('error', 'Delivery not available for your Location. Please try a different Location');
                                                        } else {
                                                            handleTabChange(e, tabValue + 1);
                                                        }
                                                    }}>Next</a>
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={tabValue} index={1} dir={theme.direction}>
                                                <div className="row choose_color_sec">
                                                    <div className="col-lg-5 col-md-12">
                                                        <div id="product-slider" className="carousel slide product-slider" data-ride="carousel">
                                                            <div className="carousel-inner">
                                                                <div className="carousel-item active">
                                                                    <div className="ps-img">
                                                                        {productImages.length > 0 && (
                                                                            <img src={productImages[0].product_image} alt={productImages[0].product_image_caption} />
                                                                        )}
                                                                        {/* <img src="assets/images/shop/net_frame.jpg" alt="" /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-12">
                                                        <div className="sin-product-details">
                                                            <div className="product_nets_tab">
                                                                {productDetail.product_attribute_types.map((attributeType, index) => (
                                                                    <div className="product-color" key={index}>
                                                                        <h5>{attributeType.attribute_type_name}</h5>
                                                                        {attributeType.attribute_type_display_type === AttributeTypeDisplayType.ColorSwatch && attributeType.product_attribute_values.map((attributeValue, valIndex) => (
                                                                            <div className="active_color" style={{ background: `${attributeValue.attribute_value_color_code}`, border: '2px solid #ffa927' }} key={valIndex}>
                                                                                <label className="checkbox-default" htmlFor="color1">
                                                                                    <input type="checkbox" id="color1" className="active-colors" checked={selectedAttributeValues.filter(val => val.attribute_value_id === attributeValue.attribute_value_id).length ? true : false} onClick={() => updateSelectedAttributeValue(attributeValue)} />
                                                                                </label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="next-prev">
                                                    <a href='#' className="add-to-cart-btn prev_btn" onClick={e => { e.preventDefault(); handleTabChange(e, tabValue - 1) }}>Prev</a>
                                                    {selectedVariant !== undefined && (
                                                        <a href='#' className="add-to-cart-btn next_btn" onClick={e => { e.preventDefault(); handleTabChange(e, tabValue + 1) }}>Next</a>
                                                    )}
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={tabValue} index={2} dir={theme.direction}>
                                                {!hidePeacefulSleep && (
                                                    <div className="row peacefull_sleep_sec">
                                                        <div className='peacefull_sleep_sec_left'>
                                                            <h3>Just One step Away From Getting Your Peaceful Sleep.</h3>
                                                            <button type="button" className="continue" onClick={() => setHidePeacefulSleep(true)}>CONTINUE</button>
                                                        </div>
                                                        <div className='peacefull_sleep_sec_right'>
                                                            <img src="assets/images/sleep.gif" alt="" />
                                                        </div>
                                                    </div>
                                                )}
                                                {hidePeacefulSleep && (
                                                    <>
                                                        <div className="row choose_dimention_sec">
                                                            <div className="col-lg-5 col-md-12">
                                                                <div id="product-slider" className="carousel slide product-slider" data-ride="carousel">
                                                                    <div className="carousel-inner">
                                                                        <div className="carousel-item active">
                                                                            <div className="ps-img">
                                                                                <img src="assets/images/shop/single-product-net.png" alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-12">
                                                                <div className="sin-product-details">
                                                                    <h3 className='we_are_help_text'>
                                                                        <span className='info_icon'><i className='fa fa-info'></i></span>
                                                                        Please click here to know your measurement
                                                                        {/* <IonRouterLink routerLink={PageLinks.PRODUCT_CUSTOMIZE + "#hash-section-1"} className='watch_video'>Watch Video</IonRouterLink> */}
                                                                        {/* <a href="#video-section-1" className='watch_video' style={{ cursor: 'pointer' }} onClick={e => {
                                                                        e.preventDefault();
                                                                        if (videoSectionRef.current) {
                                                                            videoSectionRef.current.scrollIntoView({
                                                                                behavior: 'smooth', // smooth scroll
                                                                                block: 'start' // scroll to the start of the section
                                                                            });
                                                                        }
                                                                    }}><img src='assets/images/play_button.gif' /></a> */}
                                                                        <a className='watch_video' style={{ cursor: 'pointer' }} onClick={() => setModalShow(true)}>
                                                                            <img src='assets/images/play_button.gif' />
                                                                        </a>
                                                                    </h3>
                                                                    <HowToMeasureModal
                                                                        show={modalShow}
                                                                        onHide={() => setModalShow(false)}
                                                                    />
                                                                    <h5>Enter Dimension</h5>
                                                                    <div className="product_nets_tab">
                                                                        <div className='dimension_tab'>
                                                                            <div className="product-measurement">
                                                                                <div className='product_dimenion_items'>
                                                                                    <div className="input-group">
                                                                                        <p>Enter Length</p>
                                                                                        <input type="text" className="form-control" value={length} onChange={(e) => setLength(e.target.value)} />
                                                                                        <div className="input-group-append">
                                                                                            <p className="btn_inches btn">
                                                                                                Inches
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='product_dimenion_items'>
                                                                                    <div className="input-group">
                                                                                        <p>Enter Breadth</p>
                                                                                        <input type="text" className="form-control" value={breadth} onChange={(e) => setBreadth(e.target.value)} />
                                                                                        <div className="input-group-append">
                                                                                            <p className="btn_inches btn">
                                                                                                Inches
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='product_dimenion_items'>
                                                                                    <div className="input-group">
                                                                                        <p>Enter Height</p>
                                                                                        <input type="text" className="form-control" value={height} onChange={(e) => setHeight(e.target.value)} />
                                                                                        <div className="input-group-append">
                                                                                            <p className="btn_inches btn">
                                                                                                Inches
                                                                                            </p>
                                                                                        </div>
                                                                                        <p>The optimal height of 66 inch will ideally satisfy both stature and comfort very easily.</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='product_dimenion_items'>
                                                                                    <div className="product-calculate">
                                                                                        <button type="button" className="add-to-cart-btn" onClick={fetchProductCustomSize}>CALCULATE PRICE</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {productCustomSize !== undefined ?
                                                                        <>
                                                                            <h5>{productCustomSize.product_custom_size_name} - Price Details</h5>
                                                                            <div className="col-12 add_to_cart_box">
                                                                                <div className="col-12 col-lg-6 col-md-6 p-0">
                                                                                    <div className="product-price clearfix">
                                                                                        <span className="price">
                                                                                            <span><span className="woocommerce-Price-currencySymbol">₹ </span>{Utilities.formatIndianNumber(productCustomSize.product_custom_size_price)}</span>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="add_to_cart_box_delivery_note">FREE delivery{" "}
                                                                                        {estimatedDelivery !== undefined && (
                                                                                            <EstimatedDeliveryText estimatedDeliveryDate={estimatedDelivery.estimated_delivery_date} cutoffTime={estimatedDelivery.order_process_cutoff_time} nextCutoffTime={estimatedDelivery.order_process_next_cutoff_time} />
                                                                                        )}
                                                                                        {/* Saturday, 6 May. Order within <span className="colored">4 hrs 58 mins.</span> */}
                                                                                    </div>
                                                                                    <div className="product-cart-qty">
                                                                                        <span className="qty_text">Quantity</span>
                                                                                        <div className="quantityd clearfix">
                                                                                            <button className="qtyBtn btnMinus" type="button" onClick={() => updateProductQuantity(true)}><span>-</span></button>
                                                                                            <input name="qty" value={productQuantity} title="Qty" className="input-text qty text carqty" type="text" onChange={(e) => setProductQuantity(!isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0)} />
                                                                                            <button className="qtyBtn btnPlus" type="button" onClick={() => updateProductQuantity()}>+</button>
                                                                                        </div>
                                                                                        {/* <div className="product-social">
                                                                                <IonRouterLink routerLink={PageLinks.MY_WISHLIST} className="Whislist"><i className="twi-heart"></i></IonRouterLink>
                                                                            </div> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-lg-6 col-md-6 p-0">
                                                                                    <div className="product-add-cart">
                                                                                        <button type='button' className="add-to-cart-btn" onClick={() => addToCart()}>Add To Cart</button>
                                                                                    </div>
                                                                                    <div className="product-customize">
                                                                                        <button type='button' className="add-to-cart-btn" onClick={() => addToCart(true)}>Buy Now</button>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <h5>Enter the Measurements and click on Calculate price</h5>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="video-section-1" ref={videoSectionRef}>
                                                            <div className="row how_to_measure_bg">
                                                                <div className="col-lg-6 col-md-5">
                                                                    <div className="dis-product-detail">
                                                                        <h4>How To Measure?</h4>
                                                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/XqcM8nwCm7o?controls=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-7">
                                                                    <div className="dis-product-detail">
                                                                        <h4>How To Measure?</h4>
                                                                        <p>
                                                                            Features of Venetz Mosquito net with ready to install frame <br />
                                                                            Highly Durable Mosquito net which lasts upto 10 years  <br />
                                                                            Flame Resistant  <br />
                                                                            Easy Machine Washable protective nets  <br />
                                                                            Recyclable Mosquito net material  <br />
                                                                            Smooth Finish  <br />
                                                                            6 color options  <br />
                                                                            <br />
                                                                            <strong>Technical Details of Ready to install bed nets:</strong><br />
                                                                            Made from Polyethylene <br />
                                                                            One net will last upto 10 years of span <br />
                                                                            Non tearable <br />
                                                                            <br />
                                                                            <strong>Why to use Venetz Bed Nets? </strong><br />
                                                                            For a Peaceful Sleep <br />
                                                                            Immune Booster on Disturb less sleep <br />
                                                                            Warmth Feeling inside <br />
                                                                            Ideal Temperature maintained inside the Protective bed net <br />
                                                                            Investing 60 paise per day for total Familys Health <br />
                                                                            Undisturbed Sleep improves Kids rational thinking <br />
                                                                            Buying a net is an priceless investment for your Elderly and KidS Health <br />
                                                                            <br />
                                                                            BEST MOSQUITO NET FOR BED WITH STAND TO PREVENT <br />
                                                                            FROM MOSQUITO BITES
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="next-prev">
                                                            <a href='#' className="add-to-cart-btn prev_btn" onClick={e => { e.preventDefault(); handleTabChange(e, tabValue - 1) }}>Prev</a>
                                                            {/* <a href='#' className="add-to-cart-btn next_btn" onClick={e => { e.preventDefault(); }}>Save</a> */}
                                                        </div>
                                                    </>
                                                )}

                                            </TabPanel>
                                        </Box>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default ProductCustomize;
