import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { HighlightTypes } from '../../common/Constants';
import { APIData } from '../../common/DataTypes';
import { useAppStateAPI } from '../../common/AppStateAPI';
import * as ServerAPI from '../../common/ServerAPI';
import ProductListItem from '../products/ProductListItem';

const MostPopular: React.FC = () => {

    const { showToastMsg, addProcessingRequests, reduceProcessingRequests, setCartCount } = useAppStateAPI();
    const [popularProducts, setPopularProducts] = useState<APIData.Product[]>([]);

    useEffect(() => {
        fetchCartData();
    }, []);

    const fetchCartData = () => {
        addProcessingRequests();
        ServerAPI.getHighlightItems(HighlightTypes.PopularProducts).then((response) => {
            if (response.success) {
                const items: APIData.HighlightItem[] = response.highlight_items;

                const products = items.map((item) => {
                    return {
                        product_id: item.product_id,
                        product_name: item.product_name,
                        product_short_description: item.product_short_description,
                        product_description: item.product_description,
                        product_url_name: item.product_url_name,
                        product_reference_no: '',
                        product_meta_title: '',
                        product_meta_description: '',
                        product_meta_keywords: '',
                        product_pricing_type: 0,
                        product_image_type: 0,
                        product_image_attribute_type_id: 0,
                        product_default_category_id: 0,
                        brand_id: 0,
                        product_tax_type: 0,
                        product_video_link: '',
                        product_has_attributes: 0,
                        product_is_combo: 0,
                        brand_name: '',
                        brand_description: '',
                        brand_url_name: '',
                        brand_logo: '',
                        wishlist_item_id: item.wishlist_item_id,
                        product_default_image: item.product_default_image,
                        product_default_image_caption: item.product_default_image_caption,
                        product_start_price: item.product_start_price,
                        product_start_selling_price: item.product_start_selling_price
                    }
                });
                setPopularProducts(products);

            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const addToWishlist = (productID: number) => {
        addProcessingRequests();
        ServerAPI.addItemToWishlist(productID).then((response) => {
            if (response.success) {
                setPopularProducts(prods => prods.map((product) => {
                    if (product.product_id === productID) {
                        return { ...product, wishlist_item_id: response.wishlist_item_id };
                    } else {
                        return product;
                    }
                }));
                showToastMsg('success', response.message);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const removeFromWishlist = (wishlistItemID: number) => {
        addProcessingRequests();
        ServerAPI.removeItemFromWishlist(wishlistItemID).then((response) => {
            if (response.success) {
                setPopularProducts(prods => prods.map((product) => {
                    if (product.wishlist_item_id === wishlistItemID) {
                        return { ...product, wishlist_item_id: 0 };
                    } else {
                        return product;
                    }
                }));
                showToastMsg('success', response.message);
            } else if (response.message) {
                showToastMsg('error', response.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <div>
            {popularProducts.length > 0 && (
                <section className="popular-section">
                    <div className="shape-round">
                        <span></span>
                        <span> </span>
                        <span> </span>
                        <span> </span>
                        <span> </span>
                    </div>
                    <div className="sec-heading rotate-rl"> Most <span> Popular </span></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="sec-title"> Most Popular </h2>
                                <p> Lorem Ipsum is simply dummy text </p>
                                <IonGrid>
                                    <IonRow>
                                        {popularProducts.map((popularProduct, index) => (
                                            <IonCol size="12" size-md="4" size-lg="4" key={index}>
                                                <ProductListItem product={popularProduct} addToWishlist={addToWishlist} removeFromWishlist={removeFromWishlist} />
                                            </IonCol>
                                        ))}
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};
export default MostPopular;