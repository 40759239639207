import React from 'react';
import { IonContent, IonMenu, IonMenuToggle, IonRouterLink } from '@ionic/react';

import './Menu.css'
import { PageLinks } from '../common/Constants';
import { useAppStateAPI } from '../common/AppStateAPI';

const Menu: React.FC = () => {

  const { menuCategories } = useAppStateAPI();

  return (
    <div>
      <IonMenu contentId="main-router" menuId='main-menu'>
        <IonContent className="ion-padding">
          <IonRouterLink routerLink={PageLinks.HOME} className='mobile_logo'>
            <img src="assets/images/logo.svg" alt="" />
          </IonRouterLink>
          <div className='scroll_bar'>
            <div className="mobile-menu-bottom">
              <div className="offcanvas-menu-one">
                <ul className='mobile_menu'>
                  <li><IonMenuToggle autoHide={false}><IonRouterLink className="mobile_menu_text" routerLink={PageLinks.HOME}><span> Home</span></IonRouterLink></IonMenuToggle></li>
                  <li><IonMenuToggle autoHide={false}><IonRouterLink className="mobile_menu_text" routerLink={PageLinks.PRODUCT_CUSTOMIZE}><span> Customize Your Net</span></IonRouterLink></IonMenuToggle></li>
                  {menuCategories.map((menuCategory, index) => (
                    <li key={index}><IonMenuToggle autoHide={false}><IonRouterLink className="mobile_menu_text" routerLink={PageLinks.CATEGORY + '/' + menuCategory.category_url_name}><span> {menuCategory.category_name}</span></IonRouterLink></IonMenuToggle></li>
                  ))}
                  <li><IonMenuToggle autoHide={false}><IonRouterLink className="mobile_menu_text" routerLink={PageLinks.TESTIMONIALS}><span> Testimonials</span></IonRouterLink></IonMenuToggle></li>
                  <li><IonMenuToggle autoHide={false}><IonRouterLink className="mobile_menu_text" routerLink={PageLinks.HELP_CENTER}><span> Check Me Before Ordering</span></IonRouterLink></IonMenuToggle></li>
                </ul>
              </div>
            </div>
          </div>
          <IonMenuToggle autoHide={false}>
            <IonRouterLink routerLink={PageLinks.LOGIN}>
              <div className="mobile_login_btn">
                <div className="mobile_login_btn_text"><i className="fas fa-sign-out-alt"></i> Login / Sigup</div>
                <div className="mobile_login_btn_arrow"><i className="fa fa-angle-right"></i></div>
              </div>
            </IonRouterLink>
          </IonMenuToggle>
        </IonContent>
      </IonMenu>
    </div>
  );
};

export default Menu;
