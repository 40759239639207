import { IonMenuToggle, IonRouterLink, IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { PageLinks, SearchSuggestionTypes } from '../common/Constants';
import { useAppStateAPI } from '../common/AppStateAPI';
import * as ServerAPI from '../common/ServerAPI';
import { APIData } from '../common/DataTypes';
import { useHistory } from 'react-router';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

import './Search.css';

const Header: React.FC = () => {

    const history = useHistory();
    const useDebounce = (value: string, delay: number): string => {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    }

    const autosuggestRef = useRef<Autosuggest>(null);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const { showToastMsg, addProcessingRequests, reduceProcessingRequests, cartCount, setCartCount, menuCategories, customerLogin } = useAppStateAPI();
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [searchSuggestions, setSearchSuggestions] = useState<APIData.SearchSuggestion[]>([]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            // addProcessingRequests();
            ServerAPI.getSearchSuggestions(debouncedSearchTerm).then((response) => {
                if (response.success && response.search_suggestions) {
                    const searchResponse: APIData.SearchSuggestion[] = response.search_suggestions;
                    // const suggestions: SearchData[] = searchResponse.map(sugg => {
                    //     return { key: sugg.search_suggestion_type + '|' + sugg.search_suggestion_ref, value: sugg.search_suggestion_text };
                    // });
                    setSearchSuggestions(searchResponse);
                } else {
                    showToastMsg('error', 'Could not fetch Data');
                }
            }).finally(() => {
                // reduceProcessingRequests();
            });
        } else {
            setSearchSuggestions([]);
        }
    }, [debouncedSearchTerm]); // Only call API when debounced search term changes

    useEffect(() => {
        fetchCartCount();
    }, []);

    const fetchCartCount = () => {
        addProcessingRequests();
        ServerAPI.getCartCount().then((response) => {
            if (response.success) {
                setCartCount(response.cart_count);
            } else {
                showToastMsg('error', 'Could not fetch Data');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    useEffect(() => {
        //Setting a timer since the reference is null at the moment when this function is called.
        const timer = setTimeout(() => {
            if (isSearchOpen) {
                console.log('isSearchOpen');
                if (autosuggestRef.current && autosuggestRef.current.input) {
                    console.log('Setting Focus');
                    autosuggestRef.current.input.focus();
                }
                console.log('Setting Focus', autosuggestRef.current);
            }
        }, 100);
        return () => clearTimeout(timer);
    }, [isSearchOpen]);

    return (
        <div>
            <div className="top_bar">
                <div className="top_bar_left_text">Your Peaceful sleep at the cost of 60 paise per day</div>
                <div className="top_bar_right_text">
                    {customerLogin ?
                        <IonRouterLink className="user-login" routerLink={PageLinks.MY_ORDERS}><i className="twi-person-sign1"></i><span className='user-login-text'>My Account</span></IonRouterLink>
                        :
                        <IonRouterLink className="user-login" routerLink={PageLinks.LOGIN}><i className="twi-person-sign1"></i><span className='user-login-text'>Login / Register</span></IonRouterLink>
                    }

                    <a href='tel:+919585520356' className='top_phone_no'><i className="twi-phone1"></i><span className='user-login-text'>+91 95855 20356</span></a>
                    <IonRouterLink className="user-login" routerLink={PageLinks.CONTACT}><i className="twi-map-marker-alt"></i><span className='user-login-text'>Contact us</span></IonRouterLink>
                    {/* <IonRouterLink className="user-login" routerLink={PageLinks.HELP_CENTER}><i className="twi-headphones"></i><span>Help Center</span></IonRouterLink> */}
                </div>
            </div>
            <header className="header-01 fix-header">
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        <div className="logo">
                            <IonRouterLink className='mainmenulinks' routerLink={PageLinks.HOME}><img src="assets/images/logo.svg" alt="Venetz" /></IonRouterLink>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <nav className="mainmenu mobile-menu">
                            <div className="mobile-btn">
                                <IonMenuToggle className='mainmenulinks' menu='main-menu'><i className="twi-bars"></i></IonMenuToggle>
                            </div>
                            <ul>
                                <li className="active">
                                    <IonRouterLink className='mainmenulinks' routerLink={PageLinks.HOME}>Home</IonRouterLink>
                                </li>
                                <li className="">
                                    <IonRouterLink className='mainmenulinks' routerLink={PageLinks.PRODUCT_CUSTOMIZE}>Customize Your Bed Net With Frame</IonRouterLink>
                                </li>
                                {/* {menuCategories.map((menuCategory, index) => (
                                        <li className="" key={index}>
                                            <IonRouterLink className='mainmenulinks' routerLink={PageLinks.CATEGORY + '/' + menuCategory.category_url_name}>{menuCategory.category_name}</IonRouterLink>
                                        </li>
                                    ))} */}
                                <li className="menu-item-has-children">
                                    <a className='mainmenulinks' href="#" onClick={(e) => e.preventDefault()}>Mosquito nets</a>
                                    <ul className="sub-menu">
                                        {menuCategories.map((menuCategory, index) => (
                                            <li key={index}><IonRouterLink className='mainmenulinks' routerLink={PageLinks.CATEGORY + '/' + menuCategory.category_url_name}>{menuCategory.category_name}</IonRouterLink></li>
                                        ))}
                                    </ul>
                                </li>
                                {/* <li className="menu-item-has-children">
                                        <IonRouterLink className='mainmenulinks' routerLink={PageLinks.CATEGORY}>Mosquito nets for beds</IonRouterLink>
                                        <ul className="sub-menu">
                                            <li><IonRouterLink className='mainmenulinks' routerLink={PageLinks.PRODUCT_CUSTOMIZE}>Customized Mosquito nets with frame</IonRouterLink></li>
                                            <li><IonRouterLink className='mainmenulinks' routerLink={PageLinks.PRODUCT_DETAILS}>Mosquito nets for Beds with frame</IonRouterLink></li>
                                            <li><IonRouterLink className='mainmenulinks' routerLink={PageLinks.PRODUCT_CUSTOMIZE}>Mosquito net Only</IonRouterLink></li>
                                        </ul>
                                    </li>
                                    <li className="">
                                        <IonRouterLink className='mainmenulinks' routerLink={PageLinks.PRODUCT_DETAILS}>Outdoor Mosquito Nets</IonRouterLink>
                                    </li> */}
                                <li className="">
                                    <IonRouterLink className='mainmenulinks' routerLink={PageLinks.TESTIMONIALS}>Testimonials</IonRouterLink>
                                </li>
                                <li className="">
                                    <IonRouterLink className='mainmenulinks' routerLink={PageLinks.HOWVENETZWORKS}>Check Me Before Ordering</IonRouterLink>
                                    <ul className="sub-menu">
                                        <li><IonRouterLink className='mainmenulinks' routerLink={PageLinks.HOWVENETZWORKS}>How Venetz Works?</IonRouterLink></li>
                                        <li><IonRouterLink className='mainmenulinks' routerLink={PageLinks.HOWTOORDER}>How to Order ?</IonRouterLink></li>
                                        <li><IonRouterLink className='mainmenulinks' routerLink={PageLinks.HOWTOMEASURE}>How to Measure ?</IonRouterLink></li>
                                        <li><IonRouterLink className='mainmenulinks' routerLink={PageLinks.HOWTOINSTALL}>How to Install your Ready to use Mosquito nets with frames</IonRouterLink></li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="header-cogs">
                            <a className="search search-toggles" onClick={() => setIsSearchOpen(true)}><i className="twi-search"></i></a>
                            <IonRouterLink className="carts" routerLink={PageLinks.CART}><span>{cartCount > 0 ? cartCount : ''}</span><img src="assets/images/cart.png" alt="" /></IonRouterLink>
                        </div>
                    </div>
                </div>
            </header>
            <IonModal isOpen={isSearchOpen} className='search_modal' onDidDismiss={() => setIsSearchOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Search</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setIsSearchOpen(false)}>Close</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <Autosuggest
                        suggestions={searchSuggestions}
                        onSuggestionsFetchRequested={(suggestion) => {
                            setSearchTerm(suggestion.value);
                        }}
                        onSuggestionsClearRequested={() => { console.log('Clear Requested'); setSearchSuggestions([]) }}
                        getSuggestionValue={(suggestion) => suggestion.search_suggestion_ref}
                        renderSuggestion={(suggestion, { query }) => {
                            const suggestionText = `${suggestion.search_suggestion_text}`;
                            const matches = AutosuggestHighlightMatch(suggestionText, query);
                            const parts = AutosuggestHighlightParse(suggestionText, matches);

                            return (
                                <span className={'suggestion-content'}>
                                    <span className="name">
                                        {
                                            parts.map((part, index) => {
                                                const className = part.highlight ? 'highlight' : '';

                                                return (
                                                    <span className={className} key={index}>{part.text}</span>
                                                );
                                            })
                                        }
                                    </span>
                                </span>
                            );
                        }}
                        onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
                            setIsSearchOpen(false);
                            if (suggestion.search_suggestion_type === SearchSuggestionTypes.Category) {
                                history.push(PageLinks.CATEGORY + '/' + suggestion.search_suggestion_ref);
                            } else if (suggestion.search_suggestion_type === SearchSuggestionTypes.Product) {
                                history.push(PageLinks.PRODUCT_DETAILS + '/' + suggestion.search_suggestion_ref);
                            }
                        }}
                        inputProps={{
                            placeholder: 'Type a search term',
                            value: searchTerm,
                            onChange: (event, { newValue }) => {
                                setSearchTerm(newValue);
                            },
                        }}
                        ref={autosuggestRef}
                    />
                </IonContent>
            </IonModal>
        </div>
    );
};

export default Header;

