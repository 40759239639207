import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';

import { Helmet } from 'react-helmet';

const ForgotPassword: React.FC = () => {

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Forgot Password</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/forgotpassword' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Forgot Password' />
                <meta property='og:description' content='VENETZ | Forgot Password' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">Forgot Password</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Forgot Password</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="login-section">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 mobile_mt-5">
                                    <h3 className="sec-title">Reset Your Password</h3>
                                    <div className="register-form">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <input type="password" name="full-name" placeholder="Old Password" />
                                            </div>
                                            <div className="col-lg-6">
                                                <input type="password" name="full-name" placeholder="New Password" />
                                            </div>
                                            <div className="col-lg-6">
                                                <input type="password" name="full-name" placeholder="Re Enter New Password" />
                                            </div>
                                        </div>
                                        <button type="button" className='submit-button'>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default ForgotPassword;
