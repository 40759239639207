import React from 'react';
import { IonLoading } from '@ionic/react';
import { useAppStateAPI } from '../../common/AppStateAPI';

const Preloader: React.FC = () => {
    const { processingRequests } = useAppStateAPI();

    return (
        <IonLoading
            cssClass='my-custom-class'
            isOpen={processingRequests > 0}
            animated={false}
            spinner="crescent"
            //onDidDismiss={props.onDidDismiss}
            message={'Loading...'}
            duration={10000}
        />
    )
}

export default Preloader;